<template>
  <div class="body">
    <Header>
      <div class="logoGroupe-container"><img :src="store.apiBaseUrl + store.groupes.find(groupe => groupe.id_groupe === groupeId)?.logo_groupe" alt="Images groupes" class="logoGroupe" ></div>
    </Header>
    <div class="fixe">
      <div class="colonne2">
        <div><h2 class="groupe">{{ groupeId.toUpperCase() }}</h2></div>
        <div class="nbrNageur">{{ store.countNageursByGroupe(groupeId) + " NAGEUR(S)" }}</div>
      </div>
      <div class="nbrNageurs"> {{"NAGEURS PRESENTS : " + calculerNombreDePersonnesPresentes(groupeId, new Date().toLocaleDateString()) }}</div> 
    </div>
    <!-- Affichage du message si aucun enfant n'est trouvé -->
    <div v-if="enfantsFiltre.length === 0">Aucun enfant trouvé pour ce groupe.</div>
    <!-- Liste des enfants avec leurs présences et photo -->
    <div class="container">
      <div class="card" v-for="enfant in enfantsFiltre" :key="enfant.id_enfant">
       <!-- <div class="photo">
          <img  :src="`${store.apiBaseUrl}${enfant.photo_url}`" alt="photo" @click="goDetail(enfant.id_enfant)">
        </div>-->
        <div>
          
        <!-- Image cliquable -->
        <div class="photo" @click="openModal(enfant)">
          <img :src="`${store.apiBaseUrl}${enfant.photo_url}`" alt="Photo" />
        </div>

        <!-- Modal pour afficher l'image en grand -->
        <div v-if="isModalOpen" class="modal" @click="closeModal">
          <div class="modal-content">
            <img :src="`${store.apiBaseUrl}${selectedEnfant?.photo_url}`" alt="Photo en grand" class="modal-image" />
          </div>
        </div>


      </div>


        <div class="info" @click="goDetail(enfant.id_enfant)">
          <div class="nom">
            <p :class="{ present: isPresent(enfant.id_enfant), absent: !isPresent(enfant.id_enfant) }">
            {{ enfant.Prenom.toUpperCase() }}  {{ enfant.Nom.toUpperCase() }}
            </p>
          </div> 
          <div class ="naissance">
            <div class="date">{{ enfant.date_de_naissance}}</div> 
            <div class="age">{{ age(enfant.date_de_naissance) + " ans" }}</div> 
            <div v-if="anniversaires(enfant.date_de_naissance)" class="anniversaire"><img src="@/assets/gateau-danniversaire.png" alt="Image anniversaire" ></div>
          </div>
        </div>
        <div class="bouton">
          <button @click="togglePresence(enfant.id_enfant)">
            <img :src="isPresent(enfant.id_enfant) ? require('@/assets/jaccepte.png') : require('@/assets/proche.png')" alt="Etat de présence" class="etatPresence"/>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer"  @click="goBack"><img src="@/assets/retour.png" alt="logo retour" class="retour"/></div>
</template>

<script setup>
import { onMounted, computed, defineProps, ref } from 'vue';
import { useStore } from '../stores/useStore'; // Importation du store Pinia
import { useRoute, useRouter } from 'vue-router'; // Utilisation de Vue Router pour accéder aux paramètres de l'URL
import Header from "@/components/Header.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const groupeId = route.params.groupeId; // Recupere le l'id du groupe dans la route
let selectedEnfant=null;

// Déclarer la prop evite d'avoir des alerte
defineProps({
  groupeId: String, // Le type peut être ajusté en fonction de vos données
});

//Permet de filtrer les enfants par groupe, retourne tous les enfants d'un groupe
const enfantsFiltre = computed(() => {
  const enfantfilter = store.enfants.filter(enfant => enfant.Groupe === groupeId);
  return enfantfilter;
});

//Met à jour les valeurs
onMounted(() => {
  store.getGroupes(); // Charger les groupes
  store.getEnfants(); // Charger les enfants pour ce groupe
  store.getHistorique(); // Charger l'historique des présences
});

// Fonction pour vérifier si un enfant est présent aujourd'hui
const isPresent = (enfantId) => {
  const date = new Date().toLocaleDateString(); // Date actuelle
  const verifPres = store.historique.some(row => row.id_enfant === enfantId && row.date_presence === date);
  return verifPres;
};

// Fonction pour marquer ou supprimer une présence pour un enfant
const togglePresence = async (enfantId) => {
  const enfant = store.enfants.find(e => e.id_enfant === enfantId);
  if (enfant) {
    const presence = !isPresent(enfantId);
    //await store.updatePresence(enfantId, enfant.Nom, enfant.Prenom, enfant.Groupe, presence);
    await store.updatePresence(enfantId, enfant.Groupe, presence);
  }
};

//Permet de revenir à la vue groupe
const goBack = () => {
  router.push({ name: 'groupes' });
};

//calcul l'age d'un enfant
const age = (dateNaissance) => {
  // On découpe la date au format jour/mois/année
  const [jour, mois, annee] = dateNaissance.split('/');

  // On crée un objet Date à partir de la date de naissance
  const dateNaissanceObj = new Date(annee, mois - 1, jour); // Le mois commence à 0 en JavaScript
  
  // On obtient la date d'aujourd'hui
  const today = new Date();
  
  // Calcul de l'âge en fonction de la différence d'années
  let age = today.getFullYear() - dateNaissanceObj.getFullYear();
  
  // Vérification si l'anniversaire est déjà passé cette année
  const moisActuel = today.getMonth();
  const jourActuel = today.getDate();
  if (moisActuel < dateNaissanceObj.getMonth() || (moisActuel === dateNaissanceObj.getMonth() && jourActuel < dateNaissanceObj.getDate())) {
    age--; // Si l'anniversaire n'est pas encore passé cette année, on retire 1
  }

  return age;
};

//Permet d'aller à la vue détail d'un enfant
const goDetail = (enfantId) => {
  router.push({ name: 'detailEnfants',params: { enfantId } });
};

// Filtre les présences pour le groupe et la date spécifiques
const calculerNombreDePersonnesPresentes = (groupeId, date) => {
  // Filtrer les présences pour le groupe et la date spécifiques
  const personnesPresentes = store.historique.filter((presence) =>
    presence.id_groupe === groupeId && presence.date_presence === date
  );
  // Retourner le nombre de personnes présentes
  return personnesPresentes.length;
};

//Permet de savoir si la date de naissance correspond à l'anniversaire d'un enfant
const anniversaires = (dateNaissance) => {
      const today = new Date();
      const todayDay = today.getDate();
      const todayMonth = today.getMonth() + 1
      const [day, month] = dateNaissance.split("/").map(Number);
      return day === todayDay && month === todayMonth;
    };

//Permet d'afficher la photo en grand
const isModalOpen = ref(false); // Gère l'état de la modal

// Ouvre la modal
const openModal = (enfant) => {
  isModalOpen.value = true;
  selectedEnfant=enfant;
};

// Ferme la modal
const closeModal = () => {
  isModalOpen.value = false;
};


</script>

<style scoped>


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.103); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Assurez-vous que le modal est au-dessus des autres éléments */
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 90%;  /* Ajuste l'image à 90% de la largeur de l'écran */
  max-height: 90%;  /* Ajuste l'image à 90% de la hauteur de l'écran */
  border: 5px solid white;
}



.present {
  color: var(--bleu-marine);
}
.absent {
  color: var(--rouge);
}

/* Conteneur principal de la carte */
.card {
  display: grid;
  grid-template-columns: 40% 20% 40%; /* Trois colonnes : photo, infos, bouton */
  grid-template-areas: 
    "photo info bouton"; /* Organisation des zones */
  align-items: center; /* Centre les éléments verticalement */
  padding: 10px; /* Espacement interne */
  margin: 10px auto; /* Espacement entre les cartes */
  border-bottom: 4px dashed transparent;

  border-image: repeating-linear-gradient(
    45deg,
    var(--jaune) 0,       /* Couleur de début (noire) */
    var(--jaune) 9px,     /* Longueur du point ou tiret */
    transparent 9px, /* Espacement entre les points/tirets */
    transparent 14px /* Espacement total */
  ) 1;
}


/* Photo */
.photo {
  grid-area: photo; /* Place la photo dans la zone 'photo' */
  display: flex; /* Centre l'image dans le conteneur */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Cache tout dépassement de l'image */
}

.photo img {
  max-width: 80%; /* L'image occupe 80% de la largeur du conteneur */
  max-height: 100%; /* L'image ne dépasse pas la hauteur de la carte */
  object-fit: contain; /* Maintient les proportions de l'image */
  border-radius: 5%; /* Exemple : rend l'image ronde, retirez si non souhaité */
  cursor: pointer; /* Change le curseur pour indiquer que c'est cliquable */
}

/* Infos */
.info {
  grid-area: info; /* Place les infos dans la zone 'info' */
  display: flex;
  flex-direction: column; /* Organise les éléments verticalement */
  justify-content: center;
  gap: 5px; /* Espacement entre les éléments */
}

a {
  color: #007BFF; /* Couleur du lien */
  text-decoration: none;
}

a:hover {
  text-decoration: underline; /* Souligne au survol */
}

/* Bouton */
.bouton {
  grid-area: bouton; /* Place le bouton dans la zone 'bouton' */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  font-family: 'Kid Games', sans-serif;
}

.bouton button {
  border: none;
  background-color: white ; 
  color: #fff;
  font-size: 0px;
  cursor: pointer;
}

.nbrNageurs{
  border-bottom: 6px solid var(--bleu);
  border-top: 6px solid var(--bleu);
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin-top: 10px;
  font-size: 25px; /* Taille de police en pixels */
}

.naissance img{
  overflow: hidden; /* Cache tout dépassement de l'image */
  width:50%; /* Définir la taille de l'image */
  max-width: 100%; /* S'assurer que l'image est responsive */
  margin-left: 80px;

}

.fixe{
  position: sticky;  /* L'élément devient fixe lorsqu'il atteint sa position */
  top:0px;             /* L'élément est collé au top du parent, et devient fixe à partir de là */
  z-index: 1000;
  width: 100%;
  background-color: white;
}

.colonne2 {
  display: flex;
  flex-direction: column; /* Empile les éléments de manière verticale */
  align-items: flex-end; /* Aligne tous les éléments à droite */
  width: 100%; /* Assurez-vous que la colonne prend toute la largeur de la cellule de la grille */
  margin-bottom: 5px; /* Espacement entre les éléments dans la colonne de droite */
  background-color: var(--jaune);
  padding-top: 10px;
}

.colonne2 div {
  text-align: right; /* Assure que tout le texte est aligné à droite */
  padding-right: 20px; /* Décalage intérieur léger vers la gauche */
  height: auto;
 
}

.logoGroupe {
  width:45%; /* Définir la taille de l'image */
  max-width: 100%; /* S'assurer que l'image est responsive */
  margin-top: -20;
}

.logoGroupe-container img{
  margin-top: -15px; /* Décale l'image vers le haut */
}

h2 {
  display: inline-block; /* Assurez-vous que <h2> ne prend pas toute la largeur */
  margin: 0; /* Élimine les marges par défaut sur <h2> */
  padding-bottom: 5px;
}

.groupe{
  font-family: 'Kids Magazine', sans-serif;
  font-size: 14px;
  color: white;
}

.nbrNageur{
  font-family: 'Avenir', sans-serif;
  font-weight: 500;/*Medium*/
  padding-bottom: 10px;
  color: white;
}

.nbrNageurs{
  font-family: 'Kids Magazine', sans-serif;
  font-size: 16px;
  color: var(--jaune);
  background-color: white;
}

.nom p {
  margin: 0;
  font-size: 16px;
  font-family: 'Kid Games', sans-serif;
}

.naissance {
  margin: 0;
  font-size: 16px;
  font-family: 'Avenir', sans-serif;
  font-weight: 300; /*Light*/
  color: var(--bleu-marine);
}

.etatPresence{
  width: 100%;  /* L'image prend 100% de la largeur de son parent */
  height: 100%; /* L'image prend 100% de la hauteur de son parent */
  object-fit: cover; /* Couvre toute la div sans déformer l'image */
}

.footer {
  position: fixed;          /* Fixe l'élément en bas de la fenêtre */
  bottom: 0;                /* Place le footer tout en bas */
  left: 0;                  /* Place le footer à gauche de l'écran */
  width: 100%;              /* Le footer prend toute la largeur de l'écran */
  padding: 0px;            /* Ajoute un peu d'espace autour du contenu */
  background-color: var(--bleu-marine); /* Exemple de couleur de fond */
  text-align: center;       /* Centre le texte ou le contenu du footer */
  
}

.retour {
  max-width: 50%;    /* Limite la taille maximale de l'image */
  max-height: 60px; /* Limite la hauteur maximale de l'image */
  width: auto;       /* Laisse la largeur s'ajuster automatiquement */
  height: 40%;      /* Laisse la hauteur s'ajuster automatiquement pour conserver les proportions */
}

.body{
  margin-bottom: 60px;
}

.logo {
  max-width: 80px;  /* Taille du logo */
  margin-left: 20px;

}

Header{
  background-color: var(--jaune);
}

@media (min-width: 768px) {
    .EnTete img {
    width:15%; /* Définir la taille de l'image */
    max-width: 100%; /* S'assurer que l'image est responsive */
    margin: 0px;
    padding: 0px;
  }

  .photo img {
    max-width: 40%; /* L'image occupe 80% de la largeur du conteneur */
    max-height: 60%; /* L'image ne dépasse pas la hauteur de la carte */
    object-fit: contain; /* Maintient les proportions de l'image */
    border-radius: 5%; /* Exemple : rend l'image ronde, retirez si non souhaité */
    cursor: pointer; /* Change le curseur pour indiquer que c'est cliquable */
  }
}









</style>
