<template>
  <div class="card">
    <Header>
      <p class="groupe">{{enfant.Groupe.toUpperCase()}}</p>
    </Header>
    <div class="card-body">
      <div>
        <!-- Image cliquable -->
        <div class="photo" @click="openModal">
          <img :src="`${store.apiBaseUrl}${enfant.photo_url}`" alt="Photo" />
        </div>

        <!-- Modal pour afficher l'image en grand -->
        <div v-if="isModalOpen" class="modal" @click="closeModal">
          <div class="modal-content">
            <img :src="`${store.apiBaseUrl}${enfant.photo_url}`" alt="Photo en grand" class="modal-image" />
          </div>
        </div>
      </div>
      <div class="info">
        <div class="name">
          <p>{{ enfant.Prenom.toUpperCase() }}  {{ enfant.Nom.toUpperCase() }}</p> 
        </div>
        <p class="birthdate">{{ enfant.date_de_naissance}}</p>
        <p class="age">{{ age(enfant.date_de_naissance) + " ans" }}</p>
      </div>
      <div class="modifier">
        <img src="@/assets/modifier-les-informations.png" alt="Icône de modification"   class="edit-icon" @click="goEdit(enfant.id_enfant)">
      </div> 
    </div>
  </div>
  <div>
    <div class="bouton">
      <button @click="togglePresence(enfant.id_enfant)">
        <img :src="isPresent(enfant.id_enfant) ? require('@/assets/jaccepte.png') : require('@/assets/proche.png')" alt="Etat de présence" class="etatPresence"/>
        <p>{{ isPresent(enfant.id_enfant) ? 'PRESENT' : 'ABSENT' }}</p>
      </button>
    </div>
    <div class="presence">
      <div class="presence-text">
        <!-- Première ligne (PRESENCE SUR LE MOIS) -->
        <div class="presence-item">
          <p class="presence-title">PRESENCE SUR LE MOIS</p>
          <p class="presence-number">{{ monthlyPresence(enfant.id_enfant) }}</p>
        </div>

        <!-- Deuxième ligne (TOTAL PRESENCE) -->
        <div class="presence-item">
          <p class="presence-title">TOTAL PRESENCE</p>
          <p class="presence-number">{{ totalPresence(enfant.id_enfant) }}</p>
        </div>

        <!-- Troisème ligne (icône alignée à droite) -->
        <div class="presence-item image-line">
          <img src="@/assets/recherche.png" alt="Icône de modification" class="edit-iconn" @click="showPresenceDetails()" />
        </div>
      </div>
    </div>
    <!-- Popup pour les détails -->
    <Popup ref="popupRef">
      <template v-if="enfant">
        <p class="popupNom">{{ enfant.Prenom.toUpperCase() }} {{ enfant.Nom.toUpperCase() }}</p>
          <ul>
            <li v-for="(presence, index) in filteredMonthlyPresenceDetails" :key="index">
            <strong>{{ getMonthName(index).toUpperCase() }} : </strong> {{ presence }} jours
          </li>
          </ul>
        <p class="popupTotal"><strong>TOTAL :  </strong>{{ totalPresence(enfant.id_enfant) }} jours</p>
      </template>
    </Popup>
    <!-- Affichage du message si aucun enfant n'est trouvé -->
    <div class="infoGTitre">INFOS GENERALES</div>
    <div class="infoG">
      <!-- Premier numéro de téléphone avec icônes cliquables -->
      <div class="contact" v-if="enfant.Tel1">
        <a :href="'tel:' + enfant.Tel1" class="contact-icon">
          <img src="@/assets/appel.png" alt="Appeler" class="icon phone-icon"/>
        </a>
        <a :href="'sms:' + enfant.Tel1" class="contact-icon">
          <img src="@/assets/chat.png" alt="Envoyer un SMS" class="icon sms-icon"/>
        </a>
        <span>{{ enfant.Tel1 }}</span>
       </div>

       <!-- Deuxième numéro de téléphone avec icônes cliquables -->
       <div class="contact" v-if="enfant.Tel2">
        <a :href="'tel:' + enfant.Tel2" class="contact-icon">
          <img src="@/assets/appel.png" alt="Appeler" class="icon phone-icon"/>
        </a>
        <a :href="'sms:' + enfant.Tel2" class="contact-icon">
          <img src="@/assets/chat.png" alt="Envoyer un SMS" class="icon sms-icon"/>
        </a>
        <span>{{ enfant.Tel2 }}</span>
      </div>
    </div>
    <div class="footer"  @click="goBack"><img src="@/assets/retour.png" alt="logo retour" class="retour"/></div>
  </div>
</template>
  
  <script setup>
 // eslint-disable-next-line no-unused-vars
import { onMounted, computed, onUpdated, ref, defineProps } from 'vue';
import { useRouter, useRoute } from 'vue-router'; // Utilisation de Vue Router pour accéder aux paramètres de l'URL
import { useStore} from '../stores/useStore'; // Import du store Pinia
import Header from "@/components/Header.vue";
import Popup from "@/components/Pop.vue";

//Variable
const router = useRouter();
const store = useStore();
const route = useRoute();
const enfantId = route.params.enfantId;
// Références
const popupRef = ref(null);

// Déclarer la prop pour eviter d'avoir des alertes (pas vraiment nécessaire)
defineProps({
  enfantId: String, // Le type peut être ajusté en fonction de vos données
});
  
//Méthode

//Permet de recuprer l'enfant qui correspond à l'id de l'enfant
const enfant = computed(() => {
  const enfant = store.enfants.find(g => g.id_enfant === enfantId);
  return enfant;
});

//Permet de revenir à la vue précendente
 const goBack = () => {
    const groupeId = enfant.value.Groupe;
    router.push({ name: 'enfants', params: { groupeId } });
};

//Permet d'aller à la vue d'édition
const goEdit = (enfantId) => {
  router.push({ name: 'editEnfants',params: { enfantId } });
};

// Fonction pour vérifier si un enfant est présent aujourd'hui
const isPresent = (enfantId) => {
  const date = new Date().toLocaleDateString(); // Date actuelle
  const verifPres = store.historique.some(row => row.id_enfant === enfantId && row.date_presence === date);
  return verifPres
};

// Fonction pour marquer ou supprimer une présence pour un enfant
const togglePresence = async (enfantId) => {
  const enfant = store.enfants.find(e => e.id_enfant === enfantId);
  if (enfant) {
    //await store.updatePresence(enfantId, enfant.Nom, enfant.Prenom, enfant.Groupe, !isPresent(enfantId));
    await store.updatePresence(enfantId,enfant.Groupe, !isPresent(enfantId));
  }
};

// Calcul du nombre total de présences pour un enfant
const totalPresence = (idEnfant) => {
  return store.historique.filter(row => row.id_enfant === idEnfant.toString()).length;
};

// Calcul du nombre de présences pour le mois courant
const monthlyPresence = (idEnfant) => {
  const currentMonth = new Date().getMonth(); // Mois actuel
  return store.historique.filter(row => {
    const date = new Date(row.date_presence.split('/').reverse().join('-')); // Formatage de la date pour comparaison
    return row.id_enfant === idEnfant.toString() && date.getMonth() === currentMonth;
  }).length;
};

//Permet d'afficher le details des presences d'un enfant de septembre à aout
const monthlyPresenceDetails = (idEnfant) => {
  const startDate = new Date('2024-09-01'); // Début : 1er septembre 2024
  const endDate = new Date('2025-08-31');  // Fin : 31 août 2025

  const presenceByMonth = Array(12).fill(0); // Initialise les mois de septembre à août

  store.historique.forEach(row => {
    const date = new Date(row.date_presence.split('/').reverse().join('-')); // Formatage de la date

    // Vérifie si la date est dans la plage de l'année scolaire
    if (date >= startDate && date <= endDate && row.id_enfant === idEnfant.toString()) {
      const month = date.getMonth(); // Récupère le mois de 0 (janvier) à 11 (décembre)
      presenceByMonth[month]++;      // Incrémente les présences pour ce mois
    }
  });

  return presenceByMonth; // Retourne les présences pour tous les mois
};

//Permet de reogarniser les mois pour une saison scolaire
const filteredMonthlyPresenceDetails = computed(() => {
  const allMonthlyPresences = monthlyPresenceDetails(enfant.value.id_enfant);
  // Réorganiser les mois pour avoir septembre à août
  return [...allMonthlyPresences.slice(8), ...allMonthlyPresences.slice(0, 8)];
});

const getMonthName = (index) => {
  const months = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];
  // Décale l'ordre pour commencer en septembre
  const orderedMonths = months.slice(8).concat(months.slice(0, 8));
  return orderedMonths[index];
};

//Permet d'afficher le détail des présence
const showPresenceDetails = () => {
  popupRef.value.openPopup();   // Ouvre le popup
};

//Permet de calculer l'age d'un enfant
const age = (dateNaissance) => {
  // On découpe la date au format jour/mois/année
  const [jour, mois, annee] = dateNaissance.split('/');

  // On crée un objet Date à partir de la date de naissance
  const dateNaissanceObj = new Date(annee, mois - 1, jour); // Le mois commence à 0 en JavaScript
  
  // On obtient la date d'aujourd'hui
  const today = new Date();
  
  // Calcul de l'âge en fonction de la différence d'années
  let age = today.getFullYear() - dateNaissanceObj.getFullYear();
  
  // Vérification si l'anniversaire est déjà passé cette année
  const moisActuel = today.getMonth();
  const jourActuel = today.getDate();
  if (moisActuel < dateNaissanceObj.getMonth() || (moisActuel === dateNaissanceObj.getMonth() && jourActuel < dateNaissanceObj.getDate())) {
    age--; // Si l'anniversaire n'est pas encore passé cette année, on retire 1
  }

  return age;
};

//Permet d'afficher la photo en grand
const isModalOpen = ref(false); // Gère l'état de la modal

// Ouvre la modal
const openModal = () => {
  isModalOpen.value = true;
};

// Ferme la modal
const closeModal = () => {
  isModalOpen.value = false;
};
</script>

<style scoped>
.card {
  background-color: #4A7F9E; /* Couleur de fond de la carte */
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);;
  color: white;
}

.groupe {
  font-size: 16px;
  color: var(--jaune);
  margin: 0;
  font-family: 'Kids Magazine', sans-serif;
}

.edit-icon {
  width: 40px;
  height: 40px;
}

.card-body {
  display: grid;
  grid-template-columns: 35% 50% 15%;
  padding: 10px;
}

.photo {
  display: flex; /* Centre l'image dans le conteneur */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Cache tout dépassement de l'image */
}

.photo img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
 /* Exemple : rend l'image ronde, retirez si non souhaité */
  cursor: pointer; /* Change le curseur pour indiquer que c'est cliquable */
}

.modifier {
  text-align: left;
  margin-left: 0px;
}

.info {
  text-align: left;
  padding-left: 15px;
}

.name {
  font-size: 18px;
  margin: 0;
  font-family: 'Kid Games', sans-serif;
}

.infoGTitre {
  font-size: 1.4em;
  margin: 0;
  font-family: 'Kid Games', sans-serif;
  font-size: 22px; /* Taille de police en pixels */
  background-color: rgb(250, 183, 33) ;
  color:white;
  text-align: center;
  margin-top: 20px;
}

.birthdate,
.age {
  margin: 5px 0;
  font-size: 16px;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
}

.etatPresence {
  width: 70px;  /* Ajustez la taille de l'icône comme nécessaire */
  height: 70px; 
}

.bouton{
  border-bottom: 6px solid var(--jaune);
  border-top: 6px solid var(--jaune);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-top: 10px;
}

.bouton button{
  border: none;
  background-color: white;
  font-family: 'Kid Games', sans-serif;
  color:var(--bleu-marine);
  font-size: 22px;
}

.infoG{
  display: flex;
  flex-direction: column;
  font-family: 'Avenir', sans-serif;
  font-weight: bold;
  color: var(--bleu-marine);
  margin-bottom: 50px;
  justify-content: center;  /* Centre les éléments verticalement */
  align-items: left;      /* Centre les éléments horizontalement */
  padding: 20px;            /* Espacement interne autour des éléments */
}

.infoG a{
  margin-top: 15px;
}

 .presence{
  margin-top: 25px;
  border-top: 6px solid var(--jaune);
  text-align: center;
  font-family: 'Kid Games', sans-serif;
  font-size:  22px;
  display: grid;
  grid-template-rows: repeat(4, auto) auto; /* 4 lignes pour le texte, 1 pour l'icône */
  justify-items: center; /* Centrer tous les éléments horizontalement */
  gap: 10px; /* Espacement entre les lignes */
  width: 100%;
  max-width: 600px; /* Limiter la largeur du conteneur, ajustez si nécessaire */
 }

 .presence h2{
    color: var(--violet);
 }

 .presence p{
  color: var(--bleu-marine);
}

/* Style des numbers */
.presence-number {
  font-size: 1.2em;
}

/* L'icône dans la dernière ligne */
.image-line {
  display: flex;
  justify-content: flex-end; /* Décale l'icône à droite */
  align-items: center;      /* Aligne l'icône verticalement */
  margin-top: -60px;         /* Légèrement vers le haut */
}

/* Style de l'icône */
.edit-iconn {
  cursor: pointer;
  width: 80px;  /* Taille de l'icône */
  height: 80px;
}

/* Style pour chaque bloc de contact (icônes et numéro) */
.contact {
  display: flex;
  align-items: center;  /* Aligne les icônes et le texte sur la même ligne */
  margin-bottom: 10px;
  font-size: 25px;
}

/* Icônes */
.contact-icon {
  margin-right: 10px;  /* Espacement entre l'icône et le numéro */
}


/* Taille des icônes */
.icon {
  width: 40px;
  height: 40px;
}

.phone-icon{
  margin-right: 10px;
}

/* Texte du numéro */
.contact span {
  font-size: 18px;
  color: var(--bleu-marine);
  font-family: 'Avenir', sans-serif;
  font-weight: bold;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Assurez-vous que le modal est au-dessus des autres éléments */
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 90%;  /* Ajuste l'image à 90% de la largeur de l'écran */
  max-height: 90%;  /* Ajuste l'image à 90% de la hauteur de l'écran */
  border: 5px solid white;
}

.footer {
  position: fixed;          /* Fixe l'élément en bas de la fenêtre */
  bottom: 0;                /* Place le footer tout en bas */
  left: 0;                  /* Place le footer à gauche de l'écran */
  width: 100%;              /* Le footer prend toute la largeur de l'écran */
  padding: 0px;            /* Ajoute un peu d'espace autour du contenu */
  background-color: var(--bleu-marine); /* Exemple de couleur de fond */
  text-align: center;       /* Centre le texte ou le contenu du footer */
  
}

.retour {
  max-width: 50%;    /* Limite la taille maximale de l'image */
  max-height: 60px; /* Limite la hauteur maximale de l'image */
  width: auto;       /* Laisse la largeur s'ajuster automatiquement */
  height: 40%;      /* Laisse la hauteur s'ajuster automatiquement pour conserver les proportions */
}

.popupNom{
  font-family: 'Kids Magazine', sans-serif;
  font-size: 25px;
  color: var(--bleu-marine);
}
.popupTotal{
  color: var(--bleu-marine);
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
  padding: 5px;
  border-bottom: 2px dashed transparent;
  border-image: repeating-linear-gradient(
    45deg,
    var(--jaune) 0,       /* Couleur de début (noire) */
    var(--jaune) 9px,     /* Longueur du point ou tiret */
    transparent 9px, /* Espacement entre les points/tirets */
    transparent 25px /* Espacement total */
  ) 1;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  color: var(--bleu-marine);
}

strong{
  font-weight: 100;
  font-family: 'Kid Games', sans-serif;
  color: var(--bleu-marine);
}

.popup-content {
  padding: 20px;
  text-align: left; /* Aligne le texte à gauche */
}

</style>
  