import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAuthStore } from '../stores/auth';

export const useStore = defineStore('main', () => {


  const authStore = useAuthStore(); // Accéder au store d'authentification
  // eslint-disable-next-line no-unused-vars
  const token = authStore.token; // Récupérer le token du store d'authentification
  const apiBaseUrl = process.env.VUE_APP_BASE_URL;
  const groupes = ref([]);
  const enfants = ref([]);
  const historique = ref([]);
  const historiqueAutre = ref([]);
  const url_photo = ref(null);
  const csvData = ref([]);  // Variable réactive pour stocker les données JSON reçues

  // Fonction pour récupérer les groupes depuis le serveur Node.js
  const getGroupes = async () => {
    if (!token) {
      console.error('Aucun token trouvé');
      return;
    }
    try {
      const response = await fetch(apiBaseUrl+'get/groupes', {
        headers: {
          'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization
        },
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          // Si le token est expiré, déconnecter l'utilisateur
          authStore.logout();
          throw new Error('Session expirée, veuillez vous reconnecter');
        }
        throw new Error('Accès interdit');
      }

      groupes.value = await response.json();
      //console.log('Groupes:', groupes.value);  // Traiter les données des groupes
    } catch (error) {
      console.error('Erreur lors de la récupération des groupes:', error);
    }
  };

  // Fonction pour récupérer les enfants depuis le serveur Node.js
  const getEnfants = async () => {
    if (!token) {
      console.error('Aucun token trouvé');
      return;
    }

    try {
      const response = await fetch(apiBaseUrl+'get/enfants', {
        headers: {
          'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization
        },
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          // Si le token est expiré, déconnecter l'utilisateur
          authStore.logout();
          throw new Error('Session expirée, veuillez vous reconnecter');
        }
        throw new Error('Accès interdit');
      }
      enfants.value = await response.json();
    } catch (error) {
      console.error('Erreur lors de la récupération des enfants:', error);
    }
    getHistoriqueAutre();
  };

  // Fonction pour récupérer l'historique des présences
  const getHistorique = async () => {
    if (!token) {
      console.error('Aucun token trouvé');
      return;
    }
    try {
      const response = await fetch(apiBaseUrl+'get/historique', {
        headers: {
          'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization
        },
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          // Si le token est expiré, déconnecter l'utilisateur
          authStore.logout();
          throw new Error('Session expirée, veuillez vous reconnecter');
        }
        throw new Error('Accès interdit');
      }
      historique.value = await response.json();
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'historique:', error);
    }
  };

   // Fonction pour récupérer l'historique des autres
   const getHistoriqueAutre = async () => {
    if (!token) {
      console.error('Aucun token trouvé');
      return;
    }
    try {
      const response = await fetch(apiBaseUrl+'get/historique-autre', {
        headers: {
          'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization
        },
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          // Si le token est expiré, déconnecter l'utilisateur
          authStore.logout();
          throw new Error('Session expirée, veuillez vous reconnecter');
        }
        throw new Error('Accès interdit');
      }
      historiqueAutre.value = await response.json();
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'historique des autres:', error);
    }
  };

  // Fonction pour vérifier si un enfant est présent à une date donnée
  const isPresent = async (idEnfant, date_a_verifier) => {
    await getHistorique();
    return historique.value.some(row => date_a_verifier === row.date_presence && row.id_enfant === idEnfant.toString());
  };

  // Supprimer ou ajouter une présence pour un enfant via le serveur node.js
  //const updatePresence = async (enfantId, nomEnfant, prenomEnfant, groupeId, presence) => {
  const updatePresence = async (enfantId, id_groupe, presence) => {
    if (!token) {
      console.error('Aucun token trouvé');
      return;
    }

    const date = new Date().toLocaleDateString(); //Date du jour
    const present = await isPresent(enfantId, date);
    
    if (present && presence) {
      console.log('Pas besoin, déjà présent');
    } else if (!present && !presence) {
      console.log('Pas besoin, déjà absent de la base de données');
    } else if (present && !presence) {//Si présent dans la base de donnée et l'odre de de le mettre absent // Mettre absent
      const dateReformatee = date.replaceAll('/', '-'); // ex : '11/11/2024' devient '11-11-2024'
      try {
        const response = await fetch(`${apiBaseUrl}delete/historique/${enfantId}/${dateReformatee}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
          }
        });
        if (response.ok) {
          console.log('Présence supprimée avec succès');
          getHistorique(); // Rafraîchir l'historique
        } else {
          console.error('Erreur lors de la suppression de la présence');
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de la présence :', error);
      }
      
    } else if (!present && presence) { //Si absent dans la base de donnée et l'odre de de le mettre présent // Mettre présent
      const presenceData = {
        id_enfant: enfantId,
        //prenom_enfant: prenomEnfant,
        //nom_enfant: nomEnfant,
        date_presence: date,
        id_groupe: id_groupe,
      };

      try {
        const response = await fetch(`${apiBaseUrl}post/update-presence`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 
            'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
            },
          body: JSON.stringify(presenceData),
        });
        const result = await response.json();
        if (response.ok) {
          console.log('Historique de présence ajouté:', result.message);
          getHistorique(); // Rafraîchir l'historique
        } else {
          console.error('Erreur:', result.message || result.error);
        }
      } catch (error) {
        console.error('Erreur de connexion:', error);
      }
    }
  };

    // Fonction pour vérifier si un enfant est présent à une date donnée
    const isAutrePresent = async (nomAutre, date_a_verifier) => {
      await getHistoriqueAutre();
      return historiqueAutre.value.some(row => date_a_verifier === row.date_autre && row.nom_autre === nomAutre.toString());
    };

    // Supprimer ou ajouter une présence pour à autre via le serveur node.js
  const updateAutre = async (nomAutre, totalAutre, dateAutre) => {
    if (!token) {
      console.error('Aucun token trouvé');
      return;
    }

    const present = await isAutrePresent(nomAutre, dateAutre);
  
    if (present) {//Si présent dans la BDD mettre à jour
      const presenceData = {
        nom_autre: nomAutre,
        total_autre: totalAutre,
        date_autre: dateAutre
      };

      try {
        const response = await fetch(`${apiBaseUrl}post/update-total`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 
            'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
            },
          body: JSON.stringify(presenceData),
        });
        const result = await response.json();
        if (response.ok) {
          console.log('Historique de autre modifié:', result.message);
          getHistoriqueAutre(); // Rafraîchir l'historique
        } else {
          console.error('Erreur:', result.message || result.error);
        }
      } catch (error) {
        console.error('Erreur de connexion:', error);
      }
    
      
    } else if (!present) { //Si absent de la BDD dans la base de donnée et l'odre de de le mettre présent
      const presenceData = {
        nom_autre: nomAutre,
        total_autre: totalAutre,
        date_autre: dateAutre
      };

      try {
        const response = await fetch(`${apiBaseUrl}post/update-autre`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 
            'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
            },
          body: JSON.stringify(presenceData),
        });
        const result = await response.json();
        if (response.ok) {
          console.log('Historique de autre ajouté:', result.message);
          getHistoriqueAutre(); // Rafraîchir l'historique
        } else {
          console.error('Erreur:', result.message || result.error);
        }
      } catch (error) {
        console.error('Erreur de connexion:', error);
      }
    }
  };

  // Fonction pour gérer l'upload de photo
const uploadPhoto = async (event, idEnfant) => {
  //const file = event.target.files[0];
  if (!token) {
    console.error('Aucun token trouvé');
    return;
  }
  const file = event;
  if (!file) return;
  const formData = new FormData();
  formData.append('photo', file);

  try {
    const response = await fetch(`${apiBaseUrl}photo/upload`, {
      method: 'POST',
      body: formData,
      headers: { 'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
        },
    });

    if (response.ok) {
      const data = await response.json();
      const imageUrl = data.imageUrl; // L'URL de la photo téléchargée
      // Mettre à jour l'URL de la photo dans la base de données
      url_photo.value = imageUrl;
      await updatePhotoUrl(idEnfant, imageUrl);
    } else {
      console.error('Erreur lors de l\'upload de la photo');
    }
  } catch (error) {
    console.error('Erreur:', error);
  }
};

// Fonction pour mettre à jour l'URL de la photo dans la base de données
const updatePhotoUrl = async (enfantId, imageUrl) => {
  if (!token) {
    console.error('Aucun token trouvé');
    return;
  }
  try {
    const response = await fetch(apiBaseUrl+'photo/enfants/photo', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
        },
      body: JSON.stringify({ enfantId, imageUrl })
    });

    if (response.ok) {
      getEnfants(); // Recharger les enfants pour mettre à jour la photo
    } else {
      console.error('Erreur lors de la mise à jour de l\'URL de la photo');
    }
  } catch (error) {
    console.error('Erreur:', error);
  }
};

// Fonction pour mettre à jour lenfant dans la base de données
if (!token) {
  console.error('Aucun token trouvé');
  return;
}
const updateEnfant = async (enfantMaj) => {
  try {
    const response = await fetch(apiBaseUrl+'put/enfants/update', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
        },
      body: JSON.stringify({ enfantMaj })
    });

    if (response.ok) {
      getEnfants();
    } else {
      console.error('Erreur lors de la mise à jour de lenfant');
    }
  } catch (error) {
    console.error('Erreur:', error);
  }
};

// Fonction pour supprimer la photo d'un enfant
const deletePhoto = async (photoUrl) => {
  if (!token) {
    console.error('Aucun token trouvé');
    return;
  }
  try {
    // Envoi de la requête DELETE pour supprimer la photo sur le serveur
    const response = await fetch(apiBaseUrl + 'photo/delete-photo', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`,  // Ajouter le token dans l'en-tête Authorization 
        },
      body: JSON.stringify({ photoUrl }) //l'URL de la photo à supprimer
    });

    if (response.ok) {
      console.log('Photo supprimée avec succès');
      // Mettre à jour les données après suppression (par exemple, recharger les enfants)
    } else {
      console.error('Erreur lors de la suppression de la photo');
    }
  } catch (error) {
    console.error('Erreur:', error);
  }
};

const routine = async () => {
  await getGroupes();
  await getEnfants();
  await getHistorique();
  await getHistoriqueAutre();
};

//Compte le nombre de nageur par groupe
const countNageursByGroupe = (idGroupe) => {
  return enfants.value.filter((enfants) => enfants.Groupe === idGroupe).length;
};

const update = (payload) => {
  //console.log("recu", payload.message);
  if (payload.message === "historique_presence") {
    //console.log("recu", payload.message);
    getHistorique();}
  else if (payload.message === "update_autre") {
    //console.log("recu", payload.message);
    getHistoriqueAutre();}
  else if (payload.message === "photo_url") {
    //console.log("recu", payload.message);
    getEnfants();}
  else if (payload.message === "update_enfant") {
    //console.log("recu", payload.message);
    getEnfants();}
};

// Fonction pour récupérer les données CSV en JSON
const getCsvData = async (year, month, day) => {
  if (!token) {
    console.error('Aucun token trouvé');
    return;
  }

  try {
    // Appel à l'API pour récupérer le CSV sous forme de JSON
    const response = await fetch(`${apiBaseUrl}aqua/download-csv?annee=${year}&mois=${month}&jour=${day}&saveCSV=false`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Ajouter le token dans l'en-tête Authorization
        'Accept': 'application/json' // Attendre une réponse en JSON
      }
    });

    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des données CSV');
    }

    // Récupérer la réponse JSON
    const jsonResponse = await response.json();

    if (jsonResponse && jsonResponse.data) {
      //csvData.value = jsonResponse.data;  // Stocker les données dans la variable réactive
      //console.log('Données CSV récupérées:', csvData.value); // Afficher les données dans la console
      console.log('Données CSV récupérées:', jsonResponse.data); // Afficher les données dans la console
      return jsonResponse.data;
    } else {
      console.error('Données CSV non trouvées dans la réponse');
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des données CSV:', error);
  }
};



  return {
    groupes,
    enfants,
    historique,
    url_photo,
    apiBaseUrl,
    historiqueAutre,
    csvData,
    getGroupes,
    countNageursByGroupe,
    getEnfants,
    getHistorique,
    routine,
    isPresent,
    deletePhoto,
    updatePresence,
    updateEnfant,
    uploadPhoto,
    isAutrePresent,
    updateAutre,
    getHistoriqueAutre,
    update,
    getCsvData
  };

}, {
  // Configurer la persistance de l'état dans `sessionStorage`
  persist: {
    storage: sessionStorage, // ou `localStorage` selon votre besoin
  },
});

