import { createRouter, createWebHistory } from 'vue-router';
import ListeGroupe from '../components/ListeGroupe.vue';
import EnfantsList from '../components/ListeEnfant.vue';
import DetailEnfant from '../components/DetailEnfant.vue';
import EditEnfant from '../components/EditEnfant.vue';
import EditTotal from '../components/EditTotal.vue';
import ListeAqua from '../components/ListeAqua.vue';
import { useAuthStore } from '../stores/auth.js';
// eslint-disable-next-line no-unused-vars
import {jwtDecode} from 'jwt-decode';  // Pour décoder le token
import Login from '@/components/Login.vue';

const routes = [
  { path: '/', component: Login },
  
  {
    path: '/groupes',
    name: 'groupes',
    component: ListeGroupe,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/aqua',
    name: 'aqua',
    component: ListeAqua,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/total',
    name: 'total',
    component: EditTotal,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: '/enfants/:groupeId',
    name: 'enfants',
    component: EnfantsList,
    props: true, // Passer les params à EnfantsList
    meta: { requiresAuth: true }
  },
  {
    path: '/enfants/detail_enfant/:enfantId',
    name: 'detailEnfants',
    component: DetailEnfant,
    props: true, // Passer les params à EnfantsList
    meta: { requiresAuth: true }
  },
  {
    path: '/enfants/edit_enfant/:enfantId',
    name: 'editEnfants',
    component: EditEnfant,
    props: true, // Passer les params à EnfantsList
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //Permet de remonter vers le haut de la page une fois que l'on revient sur une page
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // Si vous revenez en arrière avec "précédent", utilisez la position sauvegardée.
    /*if (savedPosition) {
      return savedPosition;
    }*/
    // Sinon, défiler en haut de la page.
    return { top: 0 };
  },
});

// Guard de navigation global
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore(); // Accéder au store d'authentification
  const token = authStore.token;

  // Vérifier si la route nécessite une authentification
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Si le token n'est pas présent ou l'utilisateur n'est pas authentifié
    if (!authStore.isAuthenticated || !token) {
      return next('/'); // Redirection vers la page de login
    }

    try {
      const decodedToken = jwtDecode(token); // Décoder le token JWT

      // Vérifier si le token a expiré
      const currentTime = Math.floor(Date.now() / 1000); // Temps actuel en secondes
      if (decodedToken.exp < currentTime) {
        authStore.logout(); // Facultatif : Déconnexion ou nettoyage du store
        return next('/'); // Si le token est expiré, rediriger vers la page de login
      }

      // Si tout est valide, continuer
      return next(); 
    } catch (error) {
      // Si une erreur se produit lors du décodage du token, rediriger vers la page de login
      authStore.logout(); // Facultatif : Nettoyer le store en cas d'erreur
      return next('/');
    }
  }

  // Si aucune authentification n'est requise, continuer la navigation
  return next();
});




export default router;
