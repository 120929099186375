<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div v-if="isVisible" class="popup-overlay" @click.self="closePopup">
      <div class="popup-content">
        <slot></slot> <!-- Permet d'insérer du contenu personnalisé -->
       <button class="close-btn" @click="closePopup">Fermer</button>
      </div>
    </div>
</template>
  
<script setup>
import { ref } from "vue";

// Propriété réactive pour contrôler la visibilité
const isVisible = ref(false);

// Méthode pour fermer le popup
const closePopup = () => {
  isVisible.value = false;
};

// Méthode pour ouvrir le popup
const openPopup = () => {
  isVisible.value = true;
};

// Exposez les méthodes pour pouvoir les appeler de l'extérieur
// eslint-disable-next-line no-undef
defineExpose({
  openPopup,
  closePopup,
});
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Centre le contenu horizontalement */
  width: 300px; /* Largeur fixe pour un design compact */
}

.names {
  text-align: left; /* Alignement des noms à gauche */
  margin-top: 15px;
}

.names p {
  margin: 5px 0;
}
.close-btn {
  margin-top: 20px;
  display: block; /* Bouton centré */
  margin-left: auto;
  margin-right: auto;
  background-color: #f04e4e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
}

.close-btn:hover {
  background-color: #d94444;
}
</style>
  