<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="login-container">
    <div class="login-form">
      <h1>WELCOME TO ...</h1>
      <img src="@/assets/logo club.png" alt="Logo" class="logo" />
      <h2>CONNEXION</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email">IDENTIFIANT</label>
          <input v-model="email" id="email" required/>
        </div>
        <div class="form-group">
          <label for="password">MOT DE PASSE</label>
          <input
            v-model="password"
            type="password"
            id="password"
            required
          />
        </div>
        <button type="submit" class="btn-submit">SE CONNECTER</button>
      </form>
      <div v-if="error" class="error-message">{{ error }}</div>
      <p class="welcome-text">Si tu as oublié le mot de passe, va voir Marc. "Mot de passe oublié" n'existe pas.</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';

// Références pour les champs du formulaire
const email = ref('');
const password = ref('');
const error = ref(null);

// Accéder au store Pinia pour l'authentification
const authStore = useAuthStore();
const router = useRouter();

// Fonction de connexion
const login = async () => {
  try {
    await authStore.login({
      email: email.value.toLocaleLowerCase(),
      password: password.value,
    });
    router.push('/groupes');
  } catch (e) {
    error.value = 'Erreur, veuillez réessayer.';
  }
};
</script>

<style scoped>
/* Conteneur général */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  height:100vh;
  background-color: var(--jaune); /* Fond uni */
  color: #333; /* Couleur du texte */
}

/* Formulaire de connexion */
.login-form {
  background-color: var(--jaune); /* Fond uni */
  padding: 0 2rem 2rem 2rem;
  width: 100%;
  height: 100%;
  max-width: 400px;
  text-align: center; /* Centre tout le contenu */
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: white; /* Couleur personnalisée */
  font-family: 'Kids Magazine', sans-serif;
}

.logo {
  width: 50%;
  height: auto;
  margin: 0 auto 0.5rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--bleu-marine); /* Fond uni */
  font-family: 'Kids Magazine', sans-serif;
}

.form-group {
  margin-bottom: 1.2rem;
  font-family: 'Kid Games', sans-serif;
  color:white;
}

label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid var(--gris);
  background-color: #ccc;
  border-radius: 30px;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: 'Avenir', sans-serif;
  font-weight: 300; /*Light*/
  color: var(--gris)
}

input:focus {
  border-color: #2575fc;
  outline: none;
  box-shadow: 0 0 4px rgba(37, 117, 252, 0.5);
}

button {
  width: 100%;
  padding: 1rem;
  background-color: var(--bleu-marine);
  font-family: 'Kid Games', sans-serif;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 20px;
}

button:hover {
  background-color: var(--bleu-marine);
  transform: scale(1.02);
}

.error-message {
  color: red;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.welcome-text {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: white;
  font-family: 'Avenir', sans-serif;
  font-weight: 800; /*Light*/
}
</style>
