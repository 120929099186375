<template>
  <div class="body">
    <router-view />
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useStore } from './stores/useStore';

const store = useStore();

// Charger les groupes et enfants lorsque l'application est montée
onMounted(() => {

});

// Fonction pour remettre la position du scroll en haut de la page
const resetScrollPosition = () => {
      window.scrollTo(0, 0);
    };
watch(() => store.view, (newView) => {
  console.log("reset",newView);
  resetScrollPosition();
});

</script>

<style scoped>


</style>
