
import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import router from './router';
import '@/assets/styles.css';
import piniaPersistedState from 'pinia-plugin-persistedstate';
//import { io } from 'socket.io-client';
//import { useStore } from '@/stores/useStore';

import { useAuthStore } from '@/stores/auth';
import { initializeSocket } from "@/services/socket";
import { configureSocketEvents } from "@/services/socketEvents";



const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPersistedState);
app.use(pinia);
app.use(router); // Utilisation de Vue Router
// Initialiser le socket sans authentification (écoute des événements globaux)
const authStore = useAuthStore(); // Accéder au store d'authentification
const token = authStore.token;
//console.log("token", token);
const socket = initializeSocket(token);
//const socket = initializeSocket();
configureSocketEvents(socket);
app.config.globalProperties.$socket = socket;
app.mount('#app');

/*const auth = useAuthStore();
//const socket = io(process.env.VUE_APP_BASE_URL); // Adresse de votre serveur Node.js
const socket = io(process.env.VUE_APP_BASE_URL, {
  auth: {
    token: auth.token,
  }
});*/

// Ajoutez le socket à l'instance globale pour l'utiliser partout
//app.config.globalProperties.$socket = socket;

// Initialisez l'écoute Socket.IO
// eslint-disable-next-line no-unused-vars
//const update = useStore(); // Initialisation du store
/*socket.on('historiqueUpdate', (payload) => {
  //console.log('Données reçues:', payload);
  update.update(payload); // Appeler l'action du store
});*/
/*socket.onAny((eventName, payload) => {
  //console.log(`Événement reçu : ${eventName}`, payload);

  // Effectuer une action en fonction du nom de l'événement
  if (eventName === 'historiqueUpdate') {
      update.update(payload); // Appeler l'action du store
  } else {
      update.update(payload);
      //console.log(`Aucune action définie pour l'événement ${eventName}`);
  }
});*/

