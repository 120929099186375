<template>
  <div class="edit-container">
    <form class="edit-form" @submit.prevent="saveChanges">
      <div class="photo-container">
        <img :src="`${store.apiBaseUrl}${store.url_photo}`" alt="Photo de l'enfant" />
        <button type="button" @click="triggerFileInput" class="change-photo-btn">Changer la photo</button>
      </div>
      <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*" style="display: none;"  />

      <div class="form-group">
        <label for="prenom">Prénom :</label>
        <input id="prenom" v-model="enfant.Prenom" type="text" required/>
      </div>

      <div class="form-group">
        <label for="nom">Nom :</label>
        <input id="nom" v-model="enfant.Nom" type="text" required/>
      </div>

      <div class="form-group">
        <label for="date-naissance">Date de Naissance :</label>
        <input id="date-naissance" v-model="formattedDate" type="date" required/>
      </div>

      <div class="form-group">
        <label for="groupe">Groupe :</label>
        <select v-model="enfant.Groupe" id="groupe" required>
          <option v-for="groupe in store.groupes" :key="groupe.id_groupe" :value="groupe.id_groupe">{{ groupe.nom_groupe }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="tel1">Téléphone 1 :</label>
        <input id="tel1" v-model="enfant.Tel1" type="tel" />
      </div>

      <div class="form-group">
        <label for="tel2">Téléphone 2 :</label>
        <input id="tel2" v-model="enfant.Tel2" type="tel" />
      </div>

      <div class="form-actions">
        <button type="submit" class="save-btn">Sauvegarder</button>
        <button type="button" class="cancel-btn" @click="cancelEdit">Annuler</button>
      </div>
    </form>
  </div> 
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute} from 'vue-router';
import { useStore } from '../stores/useStore';

const router = useRouter();
const store = useStore();
const route = useRoute();
const enfantId = route.params.enfantId;
const enfant = ref({}); // Copie locale de l'enfant (sans affecter le store directement)
const old_url_photo = ref(null);
let verif = false;
const fileInput = ref(null); // Référence à l'élément de fichier (image)

// Récupération de l'enfant du store et création d'une copie locale
onMounted(() => {
  const originalEnfant = store.enfants.find(g => g.id_enfant === enfantId);
  if (originalEnfant) {
    enfant.value = { ...originalEnfant }; // Copie simple de l'objet enfant
    store.url_photo = enfant.value.photo_url;
    old_url_photo.value = enfant.value.photo_url;
  }
});

// Gestion de la sélection du fichier
const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (!verif) verif = true;
    else  store.deletePhoto(store.url_photo); // Supprime la photo dans le cas ou la photo ne convient pas
    store.uploadPhoto(file, enfant.value.id_enfant); // Appel pour uploader la photo sans affecter l'état principal du store immédiatement
  }
};

// Fonction pour déclencher l'input de type file quand l'image est cliquée
const triggerFileInput = () => {
  fileInput.value.click();
};

// Sauvegarde des modifications et vérification s'il a un change sinon pas de sauvegarde
const saveChanges = () => {
  const originalEnfant = store.enfants.find(g => g.id_enfant === enfantId);
  // Vérifier si au moins une propriété (sauf photo_url) est différente
  const isDifferent = Object.keys(originalEnfant).some(key => key !== 'photo_url' && originalEnfant[key] !== enfant.value[key]);

  if (isDifferent) { //Si une difference info
    enfant.value.photo_url = store.url_photo;
    store.updateEnfant(enfant.value);
    //console.log('Des valeurs sont différentes, effectuer une action, photo et info', isPhoto_url_different + isDifferent );
  } else {
    // Rien ne se passe
    console.log('Aucune différence, aucune action nécessaire.');
  }
  goBack();
};

// Annulation des modifications
const cancelEdit = () => {
  const originalEnfant = store.enfants.find(g => g.id_enfant === enfantId);
  // Vérifier si au moins une propriété (sauf photo_url) est différente
  const isDifferent = Object.keys(originalEnfant).some(key => key !== 'photo_url' && originalEnfant[key] !== enfant.value[key]);
  const isPhoto_url_different = originalEnfant.photo_url !== old_url_photo.value;

  if (isDifferent && isPhoto_url_different) { //Si une difference info + photo
    store.deletePhoto(originalEnfant.photo_url);
    enfant.value = { ...originalEnfant }; // On reprend les valeurs initial
    enfant.value.photo_url = old_url_photo.value;
    store.updateEnfant(enfant.value);
    console.log('Des valeurs sont différentes, effectuer une action, photo et info', isPhoto_url_different + isDifferent );

  } else if (!isDifferent && isPhoto_url_different) { //Si une difference juste sur la photo
    store.deletePhoto(originalEnfant.photo_url);
    enfant.value.photo_url = old_url_photo.value;
    store.updateEnfant(enfant.value);
    console.log('Des valeurs sont différentes, effectuer une action, photo :', isPhoto_url_different);

  } else if (isDifferent && !isPhoto_url_different) { //Si une difference juste sur les infos
    enfant.value = { ...originalEnfant }; // On reprend les valeurs initial
    store.updateEnfant(enfant.value);
    console.log('Des valeurs sont différentes, effectuer une action, info :', isDifferent);
  } else {
    // Rien ne se passe
    console.log('Aucune différence, aucune action nécessaire.');
  }
  goBack();
};

// Retour à la page de détail de l'enfant
const goBack = () => {
  router.push({ name: 'detailEnfants', params: { enfantId } });
};

// Computed pour formater la date de naissance (et la mettre à jour dans l'objet local)
const formattedDate = computed({
  get() {
    if (enfant.value.date_de_naissance) {
      const [day, month, year] = enfant.value.date_de_naissance.split('/');
      return `${year}-${month}-${day}`;
    }
    return '';
  },
  set(value) {
    if (value) {
      const [year, month, day] = value.split('-');
      enfant.value.date_de_naissance = `${day}/${month}/${year}`; // Mise à jour locale
    }
  }
});
</script>

<style scoped>
/* Conteneur centré */
.edit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  margin-top: 50px;
}

/* Style du formulaire */
.edit-form {
  background: white;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input, select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

input:focus {
  outline: none;
  border-color: #007bff;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #0056b3;
}

.cancel-btn {
  background-color: #ccc;
  color: black;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #999;
}

.photo-container {
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.photo-container img {
  width: 50%;
  height: 50%;
  border-radius: 20px;
  object-fit: cover;
  border: 2px solid #ddd;
}

.change-photo-btn {
  display: block;
  margin: 10px auto 0;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.change-photo-btn:hover {
  background-color: #0056b3;
}

</style>
  