import { useStore } from "@/stores/useStore";

export function configureSocketEvents(socket) {
  const store = useStore();
/*
  // Connexion
  socket.on("connect", () => {
    console.log("Socket connecté !");
  });*/
/*
  // Déconnexion
  socket.on("disconnect", (reason) => {
    console.warn("Socket déconnecté :", reason);
  });*/

  // Exemple d'écoute pour l'événement 'historiqueUpdate'
  /*socket.on("historiqueUpdate", (payload) => {
    store.update(payload);
  });*/

  // Gestion des erreurs de connexion
  socket.on("connect_error", (err) => {
    console.error("Erreur de connexion Socket.IO :", err.message);
  });

  socket.onAny((eventName, payload) => {
    //console.log(`Événement reçu : ${eventName}`, payload);
  
    // Effectuer une action en fonction du nom de l'événement
    if (eventName === 'historiqueUpdate') {
        store.update(payload); // Appeler l'action du store
    } else {
        store.update(payload);
        //console.log(`Aucune action définie pour l'événement ${eventName}`);
    }
  });
}
