<!-- eslint-disable vue/multi-word-component-names -->

<template>
    <header class="header">
      <div class="logo" @click="goHome">
        <img src="@/assets/logo club.png" alt="Logo du club" />
      </div>
      <div class="slot-content">
        <slot>Contenu par défaut</slot>
      </div>
    </header>
  </template>
  
  <script setup>
  import { useRouter } from 'vue-router';

  const router = useRouter();
 
 //Permet de revenir à la page d'accueil
 const goHome = () => {
  router.push({ name: 'groupes' });
};


  </script>
  
  <style scoped>
  .header {
    display: flex;
    align-items: top;
    justify-content: space-between;
    height: 110px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  
  .logo img {
    height: 100px;
    width: auto;
  }

  .logo{
    padding-top: 0px;
  }
  
  .slot-content {
    flex: 1;
    text-align: right;    
  }
  </style>
  
  