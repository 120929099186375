<!-- eslint-disable vue/multi-word-component-names -->
<template>  
    <div class="container">
      <!-- Formulaire pour modifier la date -->
      <div class="date-form">
        <label for="date">Date:</label>
        <input type="date" id="date" v-model="selectedDate" />
  
        <button @click="fetchDataForNewDate">Mettre à jour</button>
      </div>
  
      <!-- Section du total des participants -->
      <div class="total-participants">
        <h2>Total des participants : {{ totalParticipants }}</h2>
      </div>
  
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <div v-if="Array.isArray(csvData) && csvData.length" v-for="(group, index) in csvData" :key="index">
        <div class="creneau">
          <!-- Titre avec date et équipement -->
          <h3>{{ group[0]?.dateTime }} - {{ group[0]?.equipment }}</h3>
          
          <!-- Calcul du nombre de participants pour ce créneau -->
          <div class="participants-info">
            <p><strong>Nombre de participants pour ce créneau : {{ calculateParticipantsForCreneau(group) }}</strong></p>
            
            <ul>
              <!-- Liste des utilisateurs inscrits pour ce créneau -->
              <li v-for="(entry, entryIndex) in group" :key="entryIndex">
                <strong>{{ entry.user }}</strong> ({{ entry.numParticipants }} participants)
                <br>
                <span>Email : {{ entry.userEmail }}</span>
  
                <!-- Afficher la liste des participants pour chaque entrée -->
                <ul v-if="entry.participants && entry.participants.length">
                  Participant :
                  <li v-for="(participant, participantIndex) in entry.participants" :key="participantIndex">
                    - {{ participant }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
    </div>    
    <div class="footer-container">
  <div class="footer natation" @click="goBack">
    <div class="content">
      <h2>NATATION</h2>
      <img src="@/assets/natation.png" alt="logo natation" class="natation" />
    </div>
  </div>
  <div class="footer aqua">
    <div class="content">
      <h2>AQUA CARTE</h2>
      <img src="@/assets/muscle.png" alt="logo aqua" class="aqua" />
    </div>
  </div>
</div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from '../stores/useStore';
  
  // Initialiser le store et le routeur
  const store = useStore();
  const router = useRouter();
  // eslint-disable-next-line no-unused-vars
  const route = useRoute();
  
  // Données CSV et valeurs par défaut de la date
  const csvData = ref([]);
  const currentDate = new Date();
  
  // Initialisation de la date sélectionnée avec la date actuelle
  const selectedDate = ref(currentDate.toISOString().split('T')[0]);
  
  // Fonction pour calculer le nombre de participants pour chaque créneau
  const calculateParticipantsForCreneau = (group) => {
    return group.reduce((total, entry) => {
      return total + parseInt(entry.numParticipants) || 0
    }, 0);
  }
  
  // Calcul du total des participants dans tous les créneaux
  const totalParticipants = computed(() => {
    if (!Array.isArray(csvData.value)) {
      return 0;
    }
    return csvData.value.reduce((total, group) => {
      return total + calculateParticipantsForCreneau(group)
    }, 0);
  });
  
  // Récupérer les données CSV selon la date sélectionnée
  const fetchDataForNewDate = async () => {
    const [year, month, day] = selectedDate.value.split('-');
    csvData.value = await store.getCsvData(parseInt(year), parseInt(month), parseInt(day));
    //csvData.value = store.csvData;
  };
  
  // Charger les données au montage
  onMounted(async () => {
    await fetchDataForNewDate();
  });
  
  // Permet de revenir à la vue groupe
  const goBack = () => {
    router.push({ name: 'groupes' });
  };
  </script>
  
  <style scoped>
  /* Styles pour le formulaire */
  .date-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .date-form label {
    margin-top: 5px;
  }
  
  .date-form input {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .date-form button {
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .date-form button:hover {
    background-color: #2980b9;
  }
  
  .creneau {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .creneau h3 {
    color: #2c3e50;
  }
  
  .participants-info {
    background-color: #ecf0f1;
    padding: 15px;
    border-radius: 5px;
  }
  
  .participants-info p {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  li {
    padding: 5px 0;
  }
  
  .total-participants {
    font-size: 1.5em;
    font-weight: bold;
    color: #e74c3c;
    margin-top: 30px;
    text-align: center;
  }
  
  .total-participants h2 {
    margin: 0;
  }
  
  .footer-container {
  display: flex; /* Aligne les divs horizontalement */
  width: 100%; /* Prend toute la largeur de la page */
  position: fixed;          /* Fixe l'élément en bas de la fenêtre */
  bottom: 0;                /* Place le footer tout en bas */
  left: 0;                  /* Place le footer à gauche de l'écran */
  width: 100%;              /* Le footer prend toute la largeur de l'écran */
  padding: 0px;            /* Ajoute un peu d'espace autour du contenu */

}

.footer {
  flex: 1; /* Chaque div prend 50% de l'espace */
  display: flex;
  flex-direction: column; /* Empile les éléments verticalement */
  justify-content: center; /* Centre le contenu verticalement */
  align-items: center; /* Centre le contenu horizontalement */
}

.content {
  display: flex;
  flex-direction: column; /* Empile le titre et l'image */
  align-items: center; /* Centre horizontalement le titre et l'image */
}

.image-container {
  display: flex;
  justify-content: center; /* Centre l'image horizontalement */
  align-items: center; /* Centre l'image verticalement */
  width: 100%; /* L'image reste centrée même si la div est étendue */
}

.footer img {
  max-width: 20%; /* Ajuste la taille de l'image */
  height: auto; /* Garde les proportions de l'image */
  padding-bottom: 5px;
}

.footer h2 {
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px; /* Espace entre le titre et l'image */
  font-family: 'Kids Magazine', sans-serif;
  
}

.natation {
  background-color: var(--jaune); /* Couleur de fond rouge */
}

.natation h2 {
  color: white;
  font-weight: 100;
}

.aqua {
  background-color: white; /* Couleur de fond bleu */
}

.aqua h2 {
  color: var(--jaune);
  font-weight: 100;
}
  </style>
  