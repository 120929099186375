
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="edit-container">
    <form class="edit-form" @submit.prevent="saveChanges">

      <div class="form-group">
        <label for="totalAutre">Autre :</label>
        <input v-model.number="autre.total_autre" type="number" id="totalAutre"/>
      </div>

      <div class="form-group">
        <label for="totalAqua">Aqua :</label>
        <input v-model.number="aqua.total_autre" type="number" id="totalAqua"/>
      </div>

      <div class="form-group">
        <label for="totalAqua">Aqua prévisionnel : {{ totalParticipants }} </label>
      </div>

      <div class="form-actions">
        <button type="submit" class="save-btn">Sauvegarder</button>
        <button type="button" class="cancel-btn" @click="cancelEdit">Annuler</button>
      </div>
    </form>
  </div> 
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter, useRoute} from 'vue-router';
import { useStore } from '../stores/useStore';

const router = useRouter();
// eslint-disable-next-line no-unused-vars
const store = useStore();
// eslint-disable-next-line no-unused-vars
const route = useRoute();
const autre = ref({});
const aqua = ref({}); 
const csvData = ref([]);

// Récupérer les données CSV selon la date sélectionnée
const fetchDataForNewDate = async () => {
  const today = new Date(); // Obtenir la date du jour
  const [year, month, day] = [
    today.getFullYear(),
    today.getMonth() + 1, // Les mois commencent à 0, donc on ajoute 1
    today.getDate()];
    csvData.value = await store.getCsvData(parseInt(year), parseInt(month), parseInt(day));
  };

  // Fonction pour calculer le nombre de participants pour chaque créneau
  const calculateParticipantsForCreneau = (group) => {
    return group.reduce((total, entry) => {
      return total + parseInt(entry.numParticipants) || 0
    }, 0);
  }
  
  // Calcul du total des participants dans tous les créneaux
  const totalParticipants = computed(() => {
    if (!Array.isArray(csvData.value)) {
      return 0;
    }
    return csvData.value.reduce((total, group) => {
      return total + calculateParticipantsForCreneau(group)
    }, 0);
  });
  


// Récupération de l'enfant du store et création d'une copie locale
onMounted(async () => {
  fetchDataForNewDate();
  await store.getHistoriqueAutre();
  const originalAutre = store.historiqueAutre.find(g => g.date_autre === new Date().toLocaleDateString() && g.nom_autre === 'autre');
  const originalAqua = store.historiqueAutre.find(g => g.date_autre === new Date().toLocaleDateString() && g.nom_autre === 'aqua');
  if (originalAutre) {
    autre.value = { ...originalAutre }; 
  }
  if (originalAqua) {
    aqua.value = { ...originalAqua }; 
  }
});

// Sauvegarde des modifications et vérification s'il a un change sinon pas de sauvegarde
const saveChanges = () => {
  console.log(autre.value.total_autre);
  if (autre.value.total_autre != undefined ) {
    if (autre.value.total_autre === '') autre.value.total_autre = 0;
    store.updateAutre("autre", autre.value.total_autre, new Date().toLocaleDateString())
  }
  if (aqua.value.total_autre != undefined) {
    if (aqua.value.total_autre === '') aqua.value.total_autre = 0;
    store.updateAutre("aqua", aqua.value.total_autre, new Date().toLocaleDateString())
  }
  goBack();
};

// Annulation des modifications
const cancelEdit = () => {
  goBack();
};

// Retour à la page de détail de l'enfant
const goBack = () => {
  router.push({ name: 'groupes' });
};
</script>

<style scoped>
/* Conteneur centré */
.edit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  margin-top: 50px;
}

/* Style du formulaire */
.edit-form {
  background: white;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input, select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

input:focus {
  outline: none;
  border-color: #007bff;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #0056b3;
}

.cancel-btn {
  background-color: #ccc;
  color: black;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #999;
}

.photo-container {
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.photo-container img {
  width: 50%;
  height: 50%;
  border-radius: 20px;
  object-fit: cover;
  border: 2px solid #ddd;
}

.change-photo-btn {
  display: block;
  margin: 10px auto 0;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.change-photo-btn:hover {
  background-color: #0056b3;
}

</style>
  