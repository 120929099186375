<template>
  <div class="EnTete">
    <Header>
      <div class = "text">
        <p class = "date">{{ new Date().toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' }).toUpperCase() }}</p>
        <p class = "total" @click="naviguerTotal()">{{ "TOTAL PRESENCE : " + countChildrenPresent(new Date().toLocaleDateString()) }}</p>
      </div>
    </Header>
    <div class="row">
      <!-- Barre de recherche -->
      <div class="search-bar" ref="searchBarRef">
        <img src="@/assets/chercher.png" alt="Icon" class="input-icon">
        <input type="text" v-model="query" placeholder="Recherche nom ou prénom" @keyup="rechercherEnfant"/>
        <!-- Suggestions de recherche -->
        <div v-if="resultats.length > 0" class="suggestions">
          <p v-for="enfant in resultats" :key="enfant.id_enfant" @click="naviguerVersEnfant(enfant)">
            {{ enfant.Prenom }} {{ enfant.Nom }} - {{ enfant.Groupe }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="defile">
    <div v-if="anniversaires.length > 0" class="bandeau" @click="openPopup">
      <img src="@/assets/chapeau-de-fete.png" alt="Icon" class="logoAnniversaire">
      {{ messageAnniversaire }} !
    </div>
    <!-- Appel du composant Popup avec une référence -->
    <Popup ref="popupRef">
      <p class="titreAnniversaire"> JOYEUX ANNIVERSAIRE 🎉</p>
      <div class="names">
        <p v-for="enfant in anniversaires" :key="enfant.id">
          {{ enfant.Prenom }} {{ enfant.Nom }} - {{ enfant.Groupe }}
        </p>
      </div>
    </Popup>

    <div class="container">
      <!-- Titre centré --> 
      <h2>GROUPES</h2>
      <!-- Grille pour afficher les cartes -->
      <div class="grid">
        <div class="card"
          v-for="groupe in store.groupes" :key="groupe.id_groupe" @click="selectGroupe(groupe.id_groupe)">
          <div class="card-content">
            <img :src="store.apiBaseUrl + groupe.logo_groupe" alt="Images groupes" class="logoGroupe" >
            <p class="nomGroupe">{{ groupe.nom_groupe.toUpperCase() || 'Nom manquant' }}</p>
            <p class="nbrNageur">{{ store.countNageursByGroupe(groupe.id_groupe) + " NAGEURS" || 'Nom manquant' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container">
  <div class="footer natation">
    <div class="content">
      <h2>NATATION</h2>
      <img src="@/assets/natation.png" alt="logo natation" class="natation" />
    </div>
  </div>
  <div class="footer aqua" @click="goAqua">
    <div class="content">
      <h2>AQUA CARTE</h2>
      <img src="@/assets/muscle.png" alt="logo aqua" class="aqua" />
    </div>
  </div>
</div>
</template>

<script setup>
import { onMounted, computed, ref,onBeforeUnmount } from 'vue';
import { useStore } from '../stores/useStore'; // Import du store Pinia
import { useRouter } from 'vue-router';
import Header from "@/components/Header.vue";
import Popup from "@/components/Pop.vue";

const store = useStore();
const router = useRouter();
const popupRef = ref(null); // Référence pour accéder au composant Popup

//Permet de se rendre dans vue groupe
const selectGroupe = (groupeId) => {
  router.push({ name: 'enfants', params: { groupeId } });
};

//Permet de se rendre dans vue groupe
const goAqua = () => {
  router.push({ name: 'aqua' });
};

// Filtre les présences pour la date spécifiée
const countChildrenPresent=(date) => {
    const presentOnDate = store.historique.filter(entry => entry.date_presence === date);
    const presentAutreOnDate = store.historiqueAutre.find(g => g.date_autre === new Date().toLocaleDateString() && g.nom_autre === 'autre');
    const presentAquaOnDate = store.historiqueAutre.find(g => g.date_autre === new Date().toLocaleDateString() && g.nom_autre === 'aqua');
// Retourne le nombre total de présences pour cette date
try {
  // Conversion explicite des deux valeurs en nombres
  return (Number(presentOnDate.length) + Number(presentAutreOnDate.total_autre)+ Number(presentAquaOnDate.total_autre)).toString();
} catch (error) {
  // Gestion des erreurs
  return presentOnDate.length;
}
};

// Anniversaires du jour
const anniversaires = computed(() => {
const today = new Date();
const todayDay = today.getDate();
const todayMonth = today.getMonth() + 1;
// Filtrer les enfants ayant leur anniversaire aujourd'hui
return store.enfants.filter(enfant => {
  const [day, month] = enfant.date_de_naissance.split("/").map(Number);
  return day === todayDay && month === todayMonth;});
});

// Message dynamique pour le bandeau
const messageAnniversaire = computed(() => {
const count = anniversaires.value.length;
//console.log("anniversaire",anniversaires)
if (count === 1) {
  return "1 ANNIVERSAIRE À FÊTER";
}
return `${count} ANNIVERSAIRES À FÊTER`;
});

//Permet d'ouvrir le popup anniversaire
const openPopup = () => {
  if (popupRef.value) {
    popupRef.value.openPopup(); // Appelle la méthode openPopup() du composant Popup
  }
};

// Chargement des groupes au montage du composant
onMounted(async () => {
  await store.routine(); // Récupérer les groupes au moment du montage du composant
  document.addEventListener("click", fermerSuggestionsClick);
  // Ajouter un écouteur pour la touche "Échap"
  document.addEventListener("keydown", handleKeydown);
});

onBeforeUnmount(() => {
  // Retirer l'écouteur de clic lorsque le composant est détruit
  document.removeEventListener("click", fermerSuggestionsClick);      
  // Retirer l'écouteur de la touche "Échap"
  document.removeEventListener("keydown", handleKeydown);
});


// Variables réactives pour la recherche
const query = ref("");
const resultats = ref([]);
const searchBarRef = ref(null);

// Méthode pour rechercher un enfant
const rechercherEnfant = () => {
  if (query.value.trim() === "") {
    resultats.value = [];
    return;
  }

  // Filtrage des enfants
  const lowerQuery = query.value.toLowerCase();
  resultats.value = store.enfants.filter((enfant) => enfant.Nom.toLowerCase().includes(lowerQuery) || enfant.Prenom.toLowerCase().includes(lowerQuery));
};

// Navigation vers un enfant ou un groupe
const naviguerVersEnfant = (enfant) => {
  const enfantId = enfant.id_enfant;
  router.push({ name: 'detailEnfants',params: { enfantId } });
};

// Navigation vers un enfant ou un groupe
const naviguerTotal = () => {
  router.push({ name: 'total'});
};

// Méthode pour fermer les suggestions 
const fermerSuggestionsClick = (event) => {
  if (searchBarRef.value && !searchBarRef.value.contains(event.target)) {
    fermerSuggestions();
  }
};

// Gestion de la touche "Échap"
const handleKeydown = (event) => {
  if (event.key === "Escape") {
    fermerSuggestions();  // Ferme les suggestions
  }
};

const fermerSuggestions = () => {
  resultats.value = [];
  query.value = "";
};
    
</script>

<style scoped>
/* Conteneur principal */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(84, 138, 162) ;
  padding-top: 15px;
}

/* Titre centré */
h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-weight: 100;
}

.text {
  padding-right: 5px;
}

.card-content P {
  color: white;
}

/* Grille avec deux colonnes */
.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  overflow: hidden;
  gap: 0;
}

/* Carte avec hauteur uniforme */
.card {
  padding: 15px;
  background-color: rgb(84, 138, 162);
  /*border-radius: 5px;*/
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center;     /* Centre le contenu verticalement */
  height: 120px; /* Hauteur fixe pour uniformiser les cartes */
  padding: 3rem;
  text-align: center;
}

.logoGroupe {
  width: 50%;
}

.logoAnniversaire {
  width: 10%;
}

.logo {
  max-width: 80px;  /* Taille du logo */
  margin-left: 20px;

}

/* Champs recherche */
.row {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre tout le contenu verticalement */
  align-items: center;     /* Centre tout le contenu horizontalement */   
  width: 100%;
}

.card {
  padding: 70px;
  text-align: center;
  border-bottom: 4px dashed transparent;
  border-right: 4px dashed transparent;
  border-image: repeating-linear-gradient(
    45deg,
    var(--jaune) 0,       /* Couleur de début (noire) */
    var(--jaune) 9px,     /* Longueur du point ou tiret */
    transparent 9px, /* Espacement entre les points/tirets */
    transparent 21px /* Espacement total */
  ) 1;
}

.card:nth-child(even) {
  border-right: none;
}

.card:last-child {
  border-bottom: none;
}

.EnTete {
  position: fixed; /* Reste fixe */
  top: 0; /* Reste en haut de la page */
  left: 0;
  background-color: white;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bandeau {
  padding: 10px;
  text-align: center;
  padding-top: 10px;
  margin-top: 200px;
  padding-bottom: 10px;
}

.search-bar {
  position: relative;
  width: 75%;
  height: 30px;
  display: flex;               /* Utilisation de Flexbox pour aligner les éléments en ligne */
  align-items: center;         /* Centre l'image et l'input verticalement */
  border: 1px solid  #969696;      /* Bordure autour du conteneur */
  padding: 5px;                /* Espacement interne */
  border-radius: 30px;          /* Coins arrondis */
  background-color: #ccc;
  margin-bottom: 20px;
}

.input-icon {
  width: 20px;                 /* Réduit la taille de l'icône à 20px de large */
  height: 20px;            /* Taille maximale de l'image */
  margin-right: 10px;          /* Espacement entre l'image et l'input */
}

.search-bar input {
  width: 60%;
  height: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 30px;
  border: none;                /* Supprime la bordure de l'input */
  outline: none;               /* Supprime le contour de l'input */
  background-color: #ccc;
  flex-grow: 1;                /* L'input prend toute la largeur restante */
}

.suggestions {
  grid-column: 2; /* Colonne 1 */
  grid-row: 3; /* Ligne 1 */;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;

  position: absolute; /* Permet de positionner les suggestions sous la barre de recherche */
  top: calc(100% + 5px); /* Légèrement au-dessus de 100% de la hauteur de la barre de recherche */
  left: 0%;
  right: 10%;
  max-height: 200px; /* Limiter la hauteur si nécessaire */
  overflow-y: auto; /* Permet le défilement si les suggestions dépassent la hauteur */
  background-color: white; /* Pour rendre le fond visible */
   z-index: 10; /* S'assurer que les suggestions sont au-dessus des autres éléments */
}

.suggestions p {
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
}

.suggestions p:hover {
  background-color: #eaeaea;
}

.defile {
  margin-top: 180px;
}

.date, .bandeau, h2, .total{
  font-family: 'Kids Magazine', sans-serif;
}

.date{
  font-size: 15px;
  color: var(--bleu-pale);
}

.total{
  font-size: 12px;
  color: var(--rouge);
}

.bandeau{
  background-color: var(--jaune);
  color: white;
  font-size: 14px;
}

.nomGroupe {
  font-family: 'Kid Games', sans-serif;
}

.nbrNageur {
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
}

.search-bar, .search-bar input{
  font-family: 'Avenir', sans-serif;
  font-weight: 300;
}

.names
{
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  text-align: left;
  color:var(--bleu-marine);
  font-size: 20px;
}

.titreAnniversaire{
  font-family: 'Kid Games', sans-serif;
  color:var(--bleu-marine);
  font-size:25px;
}


.footer-container {
  display: flex; /* Aligne les divs horizontalement */
  width: 100%; /* Prend toute la largeur de la page */
  position: fixed;          /* Fixe l'élément en bas de la fenêtre */
  bottom: 0;                /* Place le footer tout en bas */
  left: 0;                  /* Place le footer à gauche de l'écran */
  width: 100%;              /* Le footer prend toute la largeur de l'écran */
  padding: 0px;            /* Ajoute un peu d'espace autour du contenu */

}

.footer {
  flex: 1; /* Chaque div prend 50% de l'espace */
  display: flex;
  flex-direction: column; /* Empile les éléments verticalement */
  justify-content: center; /* Centre le contenu verticalement */
  align-items: center; /* Centre le contenu horizontalement */
}

.content {
  display: flex;
  flex-direction: column; /* Empile le titre et l'image */
  align-items: center; /* Centre horizontalement le titre et l'image */
}

.image-container {
  display: flex;
  justify-content: center; /* Centre l'image horizontalement */
  align-items: center; /* Centre l'image verticalement */
  width: 100%; /* L'image reste centrée même si la div est étendue */
}

.footer img {
  max-width: 20%; /* Ajuste la taille de l'image */
  height: auto; /* Garde les proportions de l'image */
  padding-bottom: 5px;
}

.footer h2 {
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px; /* Espace entre le titre et l'image */
  font-weight: 100;
}

.natation {
  background-color: var(--jaune); /* Couleur de fond rouge */
}

.natation h2 {
  color: white;
}

.aqua {
  background-color: white; /* Couleur de fond bleu */
}

.aqua h2 {
  color: var(--jaune);
}

@media (min-width: 768px) {
  .logoGroupe {
  width: 20%;
  }
  .logoAnniversaire {
    width: 3%;
  }
  .logo {
  width: 40%;
  }
}

</style>
