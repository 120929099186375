import { io } from "socket.io-client";

let socket = null;

// Initialiser le socket
export function initializeSocket(token = null) {
  socket = io(process.env.VUE_APP_BASE_URL, {
    auth: token ? { token } : undefined,
    reconnection: true, // Reconnexion automatique
  });

  return socket;
}

// Obtenir l'instance existante du socket
export function getSocket() {
  if (!socket) {
    throw new Error("Socket non initialisé. Appelez initializeSocket() d'abord.");
  }
  return socket;
}
