// src/stores/auth.js
import { defineStore } from 'pinia';
import { initializeSocket } from "@/services/socket";
import { configureSocketEvents } from "@/services/socketEvents";

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: null,
    token: localStorage.getItem('token') || '',  // Le token est récupéré depuis localStorage
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,  // Vérifie si le token existe
    getUser: (state) => state.user,  // Accède à l'utilisateur connecté
  },
  actions: {
    async login(credentials) {
      try {
        const response = await fetch(process.env.VUE_APP_BASE_URL+'login/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials),
        });

        if (!response.ok) {
          throw new Error('Identifiants incorrects');
        }

        const data = await response.json();
        this.user = data.user;
        this.token = data.token;
        localStorage.setItem('token', this.token);  // Sauvegarde du token dans le localStorage
          // Réinitialiser le socket avec le token
        const socket = initializeSocket(this.token);
        configureSocketEvents(socket);

  console.log("Socket réauthentifié après connexion.");
      } catch (error) {
        console.error('Erreur lors de la connexion:', error);
        throw error;
      }
    },

    logout() {
      this.user = null;
      this.token = '';  // On vide le token lors de la déconnexion
      localStorage.removeItem('token');  // Suppression du token du localStorage
    },
  },
});
